import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AddEditDepartment,
  AddEditRole,
  DeleteDepartment,
  DeleteRole,
  FetchDepartmentList,
  FetchRoleList,
} from '../actions/access-control.action';
import { tap } from 'rxjs';
import { HttpService } from '@app/core/http';
import { ToastrService } from 'ngx-toastr';

interface IAccessControlModel {
  departmentList: IDepartmentList[];
  departmentListCount: number;
  roleList: IRoleList[];
  roleListCount: number;
}
@State<IAccessControlModel>({
  name: 'accessControlState',
  defaults: {
    departmentList: [],
    departmentListCount: 0,
    roleList: [],
    roleListCount: 0,
  },
})
@Injectable()
export class AccessControlState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}
  @Selector()
  static departmentList(state: IAccessControlModel) {
    return state.departmentList;
  }
  @Selector()
  static departmentListCount(state: IAccessControlModel) {
    return state.departmentListCount;
  }
  @Selector()
  static roleList(state: IAccessControlModel) {
    return state.roleList;
  }
  @Selector()
  static roleListCount(state: IAccessControlModel) {
    return state.roleListCount;
  }

  @Action(FetchDepartmentList)
  FetchDepartmentList(
    ctx: StateContext<IAccessControlModel>,
    { param }: FetchDepartmentList
  ) {
    return this._http.post('department/list', param).pipe(
      tap((apiResult) => {
        const result = apiResult.response.dataset;
        ctx.patchState({
          departmentList: result.departments,
          departmentListCount: result.total_rows,
        });
      })
    );
  }
  @Action(AddEditDepartment)
  AddEditDepartment(
    ctx: StateContext<IAccessControlModel>,
    { param }: AddEditDepartment
  ) {
    return this._http.post('department/add-edit', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
  @Action(DeleteDepartment)
  DeleteDepartment(
    ctx: StateContext<IAccessControlModel>,
    { param }: DeleteDepartment
  ) {
    return this._http.post('department/delete', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
  @Action(FetchRoleList)
  FetchRoleList(
    ctx: StateContext<IAccessControlModel>,
    { param }: FetchRoleList
  ) {
    return this._http.post('role/list', param).pipe(
      tap((apiResult) => {
        const result = apiResult.response.dataset;
        ctx.patchState({
          roleList: result.role,
          roleListCount: result.total_rows,
        });
      })
    );
  }
  @Action(AddEditRole)
  AddEditRole(ctx: StateContext<IAccessControlModel>, { param }: AddEditRole) {
    return this._http.post('role/add-edit', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
  @Action(DeleteRole)
  DeleteRole(ctx: StateContext<IAccessControlModel>, { param }: DeleteRole) {
    return this._http.post('role/delete', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
}
