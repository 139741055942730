export class FetchDepartmentList {
  static readonly type = '[FetchDepartmentList] Post';
  constructor(public param: IFetchACLListParam) {}
}
export class AddEditDepartment {
  static readonly type = '[AddEditDepartment] Post';
  constructor(public param: IAddEditDepartmentParam) {}
}
export class DeleteDepartment {
  static readonly type = '[DeleteDepartment] Post';
  constructor(public param: IDeleteDepartmentParam) {}
}
export class FetchRoleList {
  static readonly type = '[FetchRoleList] Post';
  constructor(public param: IFetchACLListParam) {}
}
export class AddEditRole {
  static readonly type = '[AddEditRole] Post';
  constructor(public param: IAddEditRoleParam) {}
}
export class DeleteRole {
  static readonly type = '[DeleteRole] Post';
  constructor(public param: IDeleteRoleParam) {}
}
